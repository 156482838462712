import PersonDTO from "@/dto/person/PersonDTO";

export default class UpdateCompanyDetailsRequest {
    public companyId: number | null = null;

    public name: string | null = null;

    public ein: string | null = null;

    public dba: string | null = null;

    public website: string | null = null;

    public nationalTin: string | null = null;

    public phone: string | null = null;

    public federalTaxClass: string | null = null;

    public cerOfInMetaId: number | null = null;

    public filingReceiptMetaId: number | null = null;

    public naicsCode: number | null = null;

    public booksProLink: string | null = null;

    public officer: PersonDTO | null = null;
}